@import "./common.scss";

.profile-view-name {
  text-align: left;
  font-size: 24px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
  margin-bottom: 0 !important;
}
.profile-company-name {
  text-align: left;
  font-size: 18px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
}
.google-form-search-add-home {
  .css-1hwfws3 {
    padding: 0.2% 0.2% 4% 1% !important;
  }
}
.Upload-portfolio-list .ant-form-item.ant-upload {
  border: 1px dashed #d9d9d9 !important;
  width: 100%;
  padding: 15px;
  text-align: center;
  cursor: pointer;
}
.form-input-number-addonAfter {
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 12px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    // padding: 12px;
  }
}
.edit-profile-view-name {
  opacity: 1;
  border: 1.5px solid #dee1f0;
  width: 100% !important;
}

.profile-details {
  text-align: left;
  font-size: 14px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
  margin-left: 1vw !important;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.bio-text {
  text-align: left;
  font-size: 16px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1 !important;
}
.share-images {
  width: 22px !important;
  height: 19px !important;
}
.home-header.ant-layout-header {
  background-color: #ffffff;
  padding: 0% !important;
}

.profile-layout.ant-layout {
  background: #f9faff 0% 0% no-repeat padding-box;
}
.homes-card {
  margin-bottom: 2vw !important;

  .ant-card-bordered {
    margin: 0px !important;
  }
  .ant-card-body {
    padding: 0px !important;
  }
}
.card-padding-for-portfolio {
  padding-left: 24px !important;
  .ant-card-body {
    padding-left: 24px !important;
  }
  .ant-card-head {
    padding-left: 24px !important;
  }
}
.home-page-layout.ant-layout {
  background: none !important;
  border: 1px solid #ebebeb !important;
  margin-top: 13px !important;
  border-radius: 10px !important;
}
.menu-items1 {
  text-align: left;
  font-size: 18px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #3045d2 !important;
  opacity: 1;
}
.menu-items2 {
  text-align: left;
  font-size: 18px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #414555 !important;
  opacity: 1;
}
.home-add-btn {
  align-items: center !important;
  background: #f7ac31 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  opacity: 1 !important;
  width: 111px !important;
  height: 40px !important;
  border-color: #f7ac31 !important ;
  margin-top: -50px;
  margin-right: 10px;
  @media (min-width: 900px) and (max-width: 1024px) {
    width: 90px !important;
  }
}

.side-favorites,
.side-favorites-homeList {
  width: "auto";
  height: 40px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #fc572b;
  border-radius: 5px;
  opacity: 1;
  margin-bottom: 10px !important;
}

@media (min-width: 900px) and (max-width: 1024px) {
  .side-favorites {
    width: 150px !important;
  }
}
.share-favorite {
  margin-top: 8px !important;
  margin-left: 15px !important;
}
.like-favorite {
  margin-top: 8px !important;
  margin-left: 8px !important;
}
.divider-favorite {
  width: 1px !important;
  height: 40px !important;
  background: #fc572b 0% 0% no-repeat padding-box;
  border: 0px solid #fc572b;
  border-radius: 5px;
  opacity: 1;
}
.home-image {
  width: 230px !important;
  height: 210px !important;
  border-radius: 5px 0px 0px 5px;
  opacity: 1;
}
span.home-text {
  text-align: left;
  margin-top: 3px !important;
  font-size: 16px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 500 !important;
  color: #ffffff;
  margin-left: 10px !important;
  @media (min-width: 1023px) and (max-width: 1440px) {
    margin-top: 3px !important;
    font-size: 14px !important;
  }
  @media (max-width: 768px) {
    margin-top: 10px !important;
  }
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0 !important;
  margin-top: 20px !important;
}
.homes-clsname {
  text-align: left;
  font-size: 20px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
}
.home-card-body {
  .ant-card-body {
    padding: 0px !important;
  }
}
.when-to-added {
  position: absolute;
  top: 15%;
  left: 35%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  // background-color: #555;
  color: #3045d2;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #3045d229;
  border-radius: 5px;
  opacity: 1;
}
.when-to-added-buyerhome {
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #3045d2;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #3045d229;
  border-radius: 5px;
  opacity: 1;
}
.Add-home-details.ant-layout {
  padding-top: 3vw !important;
  background: #f9faff 0% 0% no-repeat padding-box;
  padding-left: 140px !important;
  padding-right: 140px !important;
  height: auto !important;
  @media (min-width: 1024px) and (max-width: 1440px) {
    // padding-top:10vw !important;
    background: #f9faff 0% 0% no-repeat padding-box;
    padding-left: 70px !important;
    padding-right: 70px !important;
    height: auto !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    // padding-top:10vw !important;
    background: #f9faff 0% 0% no-repeat padding-box;
    padding-left: 60px !important;
    padding-right: 60px !important;
    height: auto !important;
  }
  @media (max-width: 767px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.edit-save-button {
  text-align: right;
  font-size: 16px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #3045d2;
  opacity: 1;
  cursor: pointer;
}
.edit-profile-details {
  display: flex;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.step-buttons {
  text-align: right;
  font-size: 16px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
  margin-right: 120px !important;
  margin-left: 60px !important;
  background-color: #3045d2 !important;
}
.show-matches {
  text-align: right;
  font-size: 16px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #3045d2;
  opacity: 1;
  cursor: pointer;
}
.share-to-social-media {
  cursor: pointer;
  // text-align:right ;
  font-size: 13px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  margin-bottom: 5px !important;
}

.ant-select-item-option-content {
  font-size: 16px !important;
  font-family: $primaryFontFamily !important;
  @media (max-width: 500px) {
    font-size: 14px !important;
  }
}
.step-buttons:hover {
  color: #3045d2 !important;
}
.step-buttons.ant-btn:focus {
  color: #3045d2 !important;
}
.show-matches.ant-btn {
  border: 0px;
}
.show-matches:hover {
  color: #3045d2 !important;
}
.show-matches.ant-btn:focus {
  color: #3045d2 !important;
}
.ant-checkbox + span {
  font-size: 17px !important;
  letter-spacing: 0px !important;
  font-family: $primaryFontFamily;
}
.ant-upload-text {
  text-align: center;
  font-size: 13px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #99a2c3 !important;
  opacity: 1;
}
.ant-upload.ant-upload-drag {
  height: 156px !important;
}
.steps-action {
  opacity: 1;
  left: 0;
  bottom: 0;
  width: 100%;
}
.privateD-title {
  text-align: left;
  font-size: 22px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
  margin: 60px 0px 20px 0px !important;
}
.privateD-des {
  text-align: left;
  font-size: 15px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #8d8d8d;
  opacity: 1;
}
.form-titles {
  text-align: left;
  font-size: 15px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
  margin-bottom: 15px !important;
  margin-top: 23px !important;
}
.form-input {
  height: 50px !important;
  border-radius: 5px !important;
}
.form-input-number {
  height: 50px !important;
  border-radius: 5px !important;
  width: 100% !important;
}

[data-theme="compact"] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 50px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px !important;
  border-radius: 5px !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #fc572b !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #fc572b !important;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #99a2c3 !important;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: $primaryFontFamily !important;
  color: #fc572b !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: $primaryFontFamily !important;
  color: #fc572b !important;
}
.ant-steps-item-title::after {
  position: unset !important;
  top: 16px !important;
  // left: 100% !important;
  display: block !important;
  width: 395px !important;
  height: 2px;
  background: #f0f0f0;
  content: "";
}
.ant-steps-item-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: $primaryFontFamily !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fc572b !important;
  border-color: #fc572b !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fc572b !important;
  border-color: #fc572b !important;
}
.anticon.anticon-check.ant-steps-finish-icon {
  color: #ffffff !important;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #f9faff;
  border-color: #99a2c3;
}
.add-home-main {
  height: 90vw !important;
  background-color: #f9faff !important;
}
.prfile-top {
  margin-top: 9vw !important;
}
.form-input-for-media1 {
  height: 50px !important;
  border-radius: 5px !important;
  width: 180px !important;
}
.form-input-for-media2 {
  height: 50px !important;
  border-radius: 5px !important;
  width: 180px !important;
  margin-left: 60px !important;
}
.ad-image-style {
  width: 491px !important;
  height: 327px !important;
  margin-left: 50px !important;
  border: 1px solid #ebebeb !important;
}
.header-down {
  display: flex;
  justify-content: space-evenly;
  font-weight: normal;
  color: white;
  font-size: 0.9vw;
}
.header-add-home {
  text-align: left;
  font-size: 20px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}
.align-right-price {
  display: flex;
  font-size: 20px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 400 !important;
  letter-spacing: 0px;
  color: #414555;
  opacity: 1;
}
.layout-last-step {
  display: flex !important;
}
.layout-subtitles {
  font-family: $primaryFontFamily !important;
  font-weight: medium !important;
  letter-spacing: 0px;
  color: #99a2c3;
  font-size: 16px !important;

  @media (min-width: 100px) and (max-width: 767px) {
    font-size: 13px !important;
    padding-left: 0px !important;
  }
}
.searchHomes-post {
  text-align: left;
  font-size: 16px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 1.6px;
  color: #fc572b;
  text-transform: uppercase;
  opacity: 1;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 0px !important;
  height: 100%;
  background: #fff;
  border-left: 0px !important;
  border-radius: 0px !important;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.beds-row {
  margin-left: -20px;
}
.beds-img {
  width: 28px;
  height: 20px;
  @media (min-width: 200px) and (max-width: 399px) {
    width: 20px;
    height: 18px;
  }
  @media (min-width: 400px) and (max-width: 500px) {
    width: 24px;
    height: 18px;
  }
}
.beds-img-homes {
  width: 28px;
  height: 20px;
  margin-top: -10px !important;
  @media (min-width: 100px) and (max-width: 499px) {
    width: 22px !important;
    height: 16px !important;
  }
}
.baths-img {
  width: 24px;
  height: 20px;
}
@media (min-width: 1023px) and (max-width: 1440px) {
  .beds-row {
    margin-left: -16px;
  }
  .show-matches {
    text-align: right;
    font-size: 14px !important;
    font-family: $primaryFontFamily !important;
    font-weight: 600 !important;
    letter-spacing: 0px;
    color: #3045d2;
    opacity: 1;
    cursor: pointer;
  }
  .divider-favorite {
    width: 1px !important;
    height: 34px !important;
    opacity: 1;
  }

  .align-right-price {
    display: flex;
    font-size: 20px !important;
    font-family: $primaryFontFamily !important;
    font-weight: 400 !important;
    letter-spacing: 0px;
    color: #414555;
    opacity: 1;
  }
  .home-image {
    width: 13vw !important;
    height: 13.5vw !important;
    border-radius: 5px 0px 0px 5px;
    opacity: 1;
  }
  .homes-clsname {
    text-align: left;
    font-size: 15px !important;
  }

  .menu-items1 {
    text-align: left;
    font-size: 15px !important;
  }
  .menu-items2 {
    text-align: left;
    font-size: 15px !important;
  }
  .prfile-top {
    margin-top: 6vw !important;
  }
  .header-add-home {
    text-align: left;
    font-size: 15px !important;
  }
  .profile-view-name {
    text-align: left;
    font-size: 17px !important;
    font-family: $primaryFontFamily !important;
    font-weight: 600 !important;
    letter-spacing: 0px;
    color: #131419;
    opacity: 1;
  }
  .profile-company-name {
    text-align: left;
    font-size: 15px !important;
    font-family: $primaryFontFamily !important;
    font-weight: 600 !important;
    letter-spacing: 0px;
    color: #131419;
    opacity: 1;
  }

  .home-header.ant-layout-header {
    background-color: none;
    padding: 0% !important;
  }
  .homes-card .ant-card-bordered {
    margin: 0px !important;
  }
  .profile-layout {
    margin-top: 4vw !important;
  }

  .add-home-main {
    height: 100vw !important;
    background-color: #f9faff;
  }
  .ant-steps-item-title::after {
    position: unset !important;
    top: 16px !important;
    display: block !important;
    width: 350px !important;
    height: 2px;
    background: #f0f0f0;
    content: "";
  }
  .ant-steps-item-title {
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: $primaryFontFamily !important;
  }
  .media-height {
    height: 200px !important;
  }
  .ad-image-style {
    height: 200px !important;
    width: 350px !important;
    margin-left: 50px !important;
    border: 1px solid #ebebeb !important;
  }
  .form-input-for-media1 {
    height: 50px !important;
    border-radius: 5px !important;
    width: 150px !important;
  }
  .form-input-for-media2 {
    height: 50px !important;
    border-radius: 5px !important;
    width: 150px !important;
    margin-left: 60px !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: $primaryFontFamily;
    color: #fc572b !important;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: $primaryFontFamily;
    color: #fc572b !important;
  }
}

.notify-header {
  @media (min-width: 737px) and (max-width: 1024px) {
    margin-top: -5px !important;
  }
}

@media (max-width: 1024px) {
  .beds-row {
    margin-left: 0px;
  }
  .baths-img {
    width: 18px;
    height: 20px;
  }
  .home-details-profile {
    margin-left: 10vw !important;
  }
  .home-image {
    width: 15vw !important;
    height: 18vw !important;
    border-radius: 5px 0px 0px 5px;
    opacity: 1;
  }
  .align-right-price {
    display: flex;
    font-size: 12px !important;
    font-family: $primaryFontFamily !important;
    font-weight: 400 !important;
    letter-spacing: 0px;
    color: #414555;
    opacity: 1;
  }
  .share-images {
    width: 18px !important;
    height: 15px !important;
  }

  .homes-clsname.menu-items1.menu-items2.form-titles.privateD-des {
    text-align: left;
    font-size: 12px !important;
  }
  .header-add-home {
    text-align: left;
    font-size: 12px !important;
  }
  .privateD-title {
    text-align: left;
    font-size: 18px !important;
  }
  .ant-steps-item-title::after {
    position: unset !important;
    top: 16px !important;
    display: block !important;
    width: 150px !important;
    height: 2px;
    background: #f0f0f0;
    content: "";
  }
  .ant-steps-item-title {
    font-size: 12px !important;
    font-weight: 600 !important;
    font-family: $primaryFontFamily !important;
  }
  .ad-image-style {
    height: 150px !important;
    width: 250px !important;
    margin-left: 80px !important;
    border: 1px solid #ebebeb !important;
  }
  .form-input {
    height: 50px !important;
    border-radius: 5px !important;
    // width: 220px !important;
  }
  .form-input-for-media1 {
    height: 50px !important;
    border-radius: 5px !important;
    width: 100px !important;
  }
  .form-input-for-media2 {
    height: 50px !important;
    border-radius: 5px !important;
    width: 100px !important;
    margin-left: 60px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .home-details-profile {
    margin-left: 5vw !important;
  }
}

.profile-margin {
  @media (min-width: 1440px) and (max-width: 1600px) {
    margin-right: 10px;
  }
  @media (min-width: 1025px) and (max-width: 1400px) {
    margin-right: 12px;
  }
  @media (max-width: 1024px) {
    margin-right: 5px;
  }
}
.achoreTagClick::before {
  display: block;
  content: " ";
  margin-top: -180px;
  height: 180px;
  visibility: hidden;
  pointer-events: none;
}
.searchHomes-subtitles-homes {
  text-align: left;
  font-size: 1.02vw !important;
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #99a2c3;
  opacity: 1;
  margin-left: 0.5vw !important;
  margin-right: 10px !important;

  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 17px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 14px !important;
  }
  @media (min-width: 700px) and (max-width: 900px) {
    font-size: 14px !important;
    margin-left: 1vw !important;
  }
  @media (min-width: 500px) and (max-width: 699px) {
    font-size: 13px !important;
    margin-left: 1vw !important;
  }
  @media (min-width: 400px) and (max-width: 499px) {
    font-size: 12px !important;
    margin-left: 1vw !important;
  }
  @media (min-width: 200px) and (max-width: 399px) {
    font-size: 11px !important;
    margin-left: 1vw !important;
  }
}
.searchHomes-subtitles {
  text-align: left;
  font-size: 1.02vw !important;
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #99a2c3;
  opacity: 1;
  margin-left: 0.5vw !important;

  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 17px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 16px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 15px !important;
  }
  @media (min-width: 700px) and (max-width: 900px) {
    font-size: 16px !important;
    margin-left: 1vw !important;
  }
  @media (min-width: 500px) and (max-width: 699px) {
    font-size: 16px !important;
    margin-left: 1vw !important;
  }
  @media (min-width: 400px) and (max-width: 499px) {
    font-size: 16px !important;
    margin-left: 1vw !important;
  }
  @media (min-width: 200px) and (max-width: 399px) {
    font-size: 14px !important;
    margin-left: 1vw !important;
  }
}

.update-btn {
  width: 60% !important;
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 70% !important;
  }
  @media (max-width: 767px) {
    width: 100% !important;
    margin-bottom: 5vw;
  }
}
.update-profile-btn {
  height: 40px;
  margin-top: 1vw;
  @media (max-width: 767px) {
    margin-bottom: 5vw;
    margin-top: 3vw;
  }
}
.img-profile {
  @media (max-width: 767px) {
    margin-top: 3vw;
  }
}
.Btn-name {
  color: white;
  @media (min-width: 801px) and(max-width:1024px) {
    font-size: 11px;
  }
  @media (min-width: 768px) and(max-width:800px) {
    font-size: 9px;
  }
}
.profile-titles {
  @media (max-width: 767px) {
    margin-bottom: 10vw !important;
  }
}
.card-body-style {
  margin-top: 1vw;
  @media (min-width: 768px) and(max-width: 2080px) {
    padding: 24px !important;
  }
}

.upgrade-add-btn {
  height: 50px;
  font-family: Poppins;
  width: 25%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  @media (max-width: 767px) {
    width: 50%;
    font-size: 12px !important;
  }
}
.upgrade-add-zip {
  width: 25%;
  @media (max-width: 767px) {
    width: 40%;
    font-size: 12px !important;
  }
}
.upgrade-add-btn-sub {
  height: 50px;
  font-family: Poppins;
  width: 25%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  @media (max-width: 767px) {
    width: 50%;
    font-size: 12px !important;
  }
}
.form-input-profile {
  height: 50px !important;
  border-radius: 5px !important;
  width: 75%;
  @media (max-width: 767px) {
    width: 60%;
  }
}
.input-width {
  border-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 50px !important;
  width: 75% !important;
  @media (min-width: 1025px) and(max-width:1280px) {
    width: 65% !important;
  }
  @media (min-width: 1281px) and(max-width:1440px) {
    width: 70% !important;
  }
  @media (max-width: 1024px) {
    width: 60% !important;
  }
}
.add-user-width {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  float: right;
  color: white;
  font-family: Poppins;
  font-size: 16px;
  height: 50px;
  width: 25% !important;
  @media (min-width: 1025px) and(max-width:1280px) {
    width: 35% !important;
  }
  @media (min-width: 1281px) and(max-width:1440px) {
    width: 30% !important;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    width: 40% !important;
  }
}
.form-input-profile1 {
  // height: 50px !important;
  border-radius: 5px !important;
  width: 75%;
  @media (min-width: 100px) and(max-width: 499px) {
    width: 60%;
    // height: 160px !important;
  }
  @media (min-width: 500px) and(max-width: 767px) {
    width: 60%;
    // height: 110px !important;
  }
}

@media (min-width: 1281px) and(max-width: 1366px) {
  .upgrade-add-more {
    font-size: 14px;
  }
  .upgrade-add-more-sub {
    font-size: 14px;
  }
  .upgrade-add-btn {
    font-size: 14px;
  }
  .upgrade-add-btn-sub {
    font-size: 14px;
  }
}
@media (min-width: 1200px) and(max-width: 1280px) {
  .upgrade-add-more-sub {
    font-size: 13px;
    height: 42px;
  }
  .upgrade-add-more {
    font-size: 13px;
  }
  .upgrade-add-btn {
    font-size: 13px;
  }
  .upgrade-add-btn-sub {
    font-size: 13px;
    height: 80px;
  }
}
@media (min-width: 1024px) and(max-width: 1199px) {
  .upgrade-add-more {
    font-size: 12px;
  }
  .upgrade-add-more-sub {
    font-size: 12px;
    height: 42px;
  }
  .upgrade-add-btn-sub {
    font-size: 12px;
    width: 30%;
    height: 80px;
  }
  .upgrade-add-btn {
    font-size: 12px;
    width: 30%;
  }
  .form-input-profile {
    width: 70%;
  }
  .form-input-profile1 {
    width: 70%;
  }
  .upgrade-add-zip {
    width: 30%;
  }
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 5px !important;
  @media (max-width: 76px) {
    margin-left: 2px !important;
  }
}

.profile-details-mobile-view {
  .ant-card-body {
    padding: 24px !important;
  }
}
.edit-delete-option-mobile {
  display: none !important;
}
.hide-desktop-version-none {
  display: block !important;
  margin-top: 5px !important;
  margin-bottom: 7vw;
}
.show-on-profile-details-mobile {
  display: none !important;
}
.subscription-history {
  background-color: #f9faff;
  height: 40px;
  margin: 5px 0 5px;
}
.subscription-history-card-paddingTop {
  margin-top: -15px !important;
}
.plan-card-view-mobile {
  text-align: center;
  padding: 2px 5px;
}
.padding-for-show-all-cards {
  padding: 0px !important;
}
.subscription-plans-modal-view {
  font-family: $primaryFontFamily;
  font-style: normal;
  padding: 50px !important;
  background-color: #f9faff !important;
  @media (min-width: 1024px) and (max-width: 1280px) {
    padding: 20px !important;
  }
}
.plan-card-margin-set {
  margin-bottom: 0% !important;
}
.buyer-agent-modal.plans-view {
  padding: 50px !important;
  border-radius: 10px !important;
}
.buyer-agent-modal.payment-view {
  margin-top: 2% !important;
  padding: 50px !important;
  border-radius: 10px !important;
}
@media (min-width: 210px) and(max-width: 576px) {
  .padding-for-show-all-cards {
    padding: 10px !important;
  }
  .profile-details-mobile-view {
    .ant-card-body {
      padding: 0px !important;
    }
  }
  .buyer-agent-modal.payment-view {
    margin-top: 2% !important;
    padding: 0px !important;
    border-radius: 10px !important;
  }
  .buyer-agent-modal.plans-view {
    padding: 0px !important;
    border-radius: 10px !important;
  }
  .subscription-plans-modal-view {
    font-family: $primaryFontFamily;
    font-style: normal;
    padding: 30px !important;
    // padding-top: 30px !important;
    // padding-left: 20px !important;
    // padding-right: 10px !important;
    // padding-bottom: 20px !important;
    background-color: #f9faff !important;
  }
  .plan-card-margin-set {
    margin-bottom: 5% !important;
  }
  .subscription-history-card-paddingTop {
    margin-top: 0px !important;
  }
  .plan-card-view-mobile {
    text-align: left;
    padding: 2px 20px;
  }
  .homes-clsname {
    padding-left: 24px !important;
    padding-top: 24px !important;
  }
  .edit-delete-option-mobile {
    padding-top: 10%;
    padding-left: 5% !important;
    display: block !important;
  }
  .hide-desktop-version-none {
    display: none !important;
  }
  .show-on-profile-details {
    display: block !important;
  }
  .subscription-history {
    background-color: #f9faff;
    height: 100%;
    margin: 5px 0 5px;
  }
}
.subscription-card {
  @media (max-width: 992px) {
    margin-bottom: 2vw !important;
  }
}
.subscrptioncard-width {
  height: 400px;
  @media (min-width: 992px) and (max-width: 1024px) {
    height: 480px !important;
    width: 155px !important;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    height: 480px !important;
    width: 165px !important;
  }
  @media (min-width: 1281px) and (max-width: 1366px) {
    height: 450px !important;
  }
}
.subscription-plans-modal-view {
  @media (min-width: 992px) and (max-width: 1280px) {
    .ant-col-lg-offset-1 {
      margin-left: 2.16666667% !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1280px) {
    .ant-col-lg-5 {
      display: block;
      flex: 0 0 22.83333333%;
      max-width: 22.833333%;
    }
  }
  @media (min-width: 1200px) {
    .ant-col-xl-offset-1 {
      margin-left: 2.16666667% !important;
    }
  }
  @media (min-width: 1200px) {
    .ant-col-xl-5 {
      display: block;
      flex: 0 0 22.83333333%;
      max-width: 22.833333%;
    }
  }
}

.Cancel-Subscription {
  @media (min-width: 992px) and(max-width:1024px) {
    width: 110% !important;
  }
}
.row-margin-subscription {
  margin-left: -5% !important;
  @media (min-width: 992px) and (max-width: 1280px) {
    margin-left: -15px !important;
  }
}
.home-header,
.ant-layout-header {
  height: fit-content !important;
}
.testimonila-desc {
  font-size: 14px;
  text-align: justify;
}
.testmonial-star {
  margin-bottom: 5px;
}
.Cancel-portfolio {
  position: absolute;
  margin: 5px;
  z-index: 1;
  right: 0;
  @media (max-width: 500px) {
    right: -15px !important;
  }
}
.profile-update-image {
  .ant-upload-list-text-container,
  .ant-upload-list-picture-container {
    display: none;
  }
}
.antd-img-crop-modal .antd-img-crop-control button {
  color: #000000;
}
.antd-img-crop-modal .antd-img-crop-control.zoom button {
  font-size: 24px !important;
}

.facebook-text {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.ant-list-empty-text {
  display: none;
}
