@import "./common.scss";

.size1-homes {
  font-size: 1.07vw;
  margin-bottom: 10px !important;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.27vw;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.5vw !important;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 1.7vw;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    font-size: 2.5vw;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 3.5vw;
    margin-bottom: 5px !important;
  }
}
.size1 {
  font-size: 1.07vw;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.5vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 1.7vw;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    font-size: 2vw;
  }
  @media (min-width: 200px) and (max-width: 500px) {
    font-size: 2.8vw;
  }
}
.size1-loan {
  font-size: 1.07vw;
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 1.7vw;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    font-size: 2vw;
  }
  @media (max-width: 500px) {
    font-size: 2.5vw;
  }
}
.profile-name {
  font-size: 1.23vw;
}
.profile-view-link {
  font-size: 1.1vw;
  font-family: $primaryFontFamily;
  font-style: normal;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.7vw !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.5vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 3vw;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 4vw;
  }
}
.ant-card-head-title {
  font-size: 1.23vw;
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: 600;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.47vw;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.6vw;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 2vw !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.9vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 3vw;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 4.2vw !important;
  }
}
.estate-background {
  background: #e9f0fd 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.ask-question-button {
  color: #1890ff;
  border-color: #1890ff;
  border-radius: 20px;
  padding: 5px 25px;
}
.createButton {
  min-width: 16vw;
}
.createButton1 {
  min-width: 16vw;
  @media (max-width: 501px) {
    display: none;
  }
}
.createButton2 {
  min-width: 16vw;
  @media (min-width: 502px) {
    display: none;
  }
}

.button-inner-text-size {
  font-size: 1vw;
  margin: 5px;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.5vw !important;
    margin: 1%;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.5vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 2.5vw;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 4vw;
  }
}

.divider-detail {
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 100px) and (max-width: 900px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.ant-card {
  width: 100%;
}

.row-padding {
  padding-bottom: 2vw;
}

.padding-text-center {
  padding-top: 5%;
  text-align: left;
}

.font-and-textalign {
  margin-bottom: 0 !important;
  @media (min-width: 1024px) {
    font-size: 1.1vw;
  }
}

.padding-loan-btn {
  padding: 8%;
  @media (min-width: 500px) and (max-width: 992px) {
    padding: 5%;
  }
}
.modal-title {
  font-size: 1.2vw;
  color: blue;
}

.contact-now {
  font-size: 1.02vw;
  color: #fc572b;
  text-align: center;
  border: none;
  box-shadow: none;
  padding: auto;
  .ant-btn {
    color: #fc572b;
  }
}

.experience {
  margin-top: 0.5vw;
  font-size: 0.9vw;
}

.modal-add {
  width: -webkit-fill-available;
  margin-top: 3%;
}
.modal-add1 {
  .ant-card-body {
    padding: 0px;
  }
}
.ant-modal-body {
  padding-top: 0px;
  padding-bottom: 0.73vw;
  padding-left: 0.73vw;
  padding-right: 0.73vw;
}
.card-body {
  .ant-card-body {
    padding: 1.16vw;
  }
}
.contact-title {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.02vw;
}
.divider-margin {
  margin: 0px;
}

.contact-modal {
  .ant-modal-header {
    background: #414555 0% 0% no-repeat padding-box;
    text-align: center;
    padding: "1.17vw 1.75vw";
  }
  .ant-modal-close {
    color: white;
  }
  .ant-modal-close-x {
    font-size: 1.2vw;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .padding {
    padding: 1vw;
  }
}
.Approve-loan {
  background: none;
  .ant-modal-header {
    background: white;
    border: none;
  }
  .ant-modal-body {
    padding: 0.7vw 2vw;
  }
}
.Approval-loan-card {
  border: 1px solid #3045d22b;
  border-radius: 10px;
  opacity: 1;
  padding: 1vw;
}

.view-details {
  .ant-select-selection-item {
    font-size: 0.93vw;
    padding-right: 1.2vw;
    padding-left: 0px;
  }
  .ant-select-arrow {
    color: #007bff;
    font-size: 0.93vw;
    @media (max-width: 500px) {
      font-size: 1.93vw;
      text-align: center;
    }
  }
}
.rating {
  .ant-rate {
    font-size: 1.2vw;
    @media (max-width: 500px) {
      font-size: 3.2vw !important;
    }
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 0.7svw;
  }
}
.ant-rate-text {
  font-size: 1vw;
  @media (max-width: 500px) {
    font-size: 3.2vw !important;
  }
}

.approved-button {
  .ant-btn {
    background: #4c2aca 0% 0% no-repeat padding-box;
    padding: 0.4vw 1.1vw;
    font-size: 1vw;
    width: 100%;
  }
  .ant-btn > span {
    font-size: 0.93vw;
    @media (max-width: 500px) {
      font-size: 1.93vw;
    }
  }
}

.property-detail-value {
  font-size: 0.9vw;
  color: #131419;
  letter-spacing: 0px;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.7vw !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.2vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 2.3vw;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 3.5vw;
  }
}
.property-description {
  font-size: 0.9vw;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.7vw !important;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 2.7vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 2.8vw;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 4vw !important;
  }
}

.property-detail-name {
  font-size: 0.9vw;
  color: #414555;
  letter-spacing: 0px;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.7vw !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.2vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 2.3vw;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 3.5vw;
  }
}
.img-detail-page {
  background-color: #d4d8f1fa;
  width: 4.6vw;
  height: 4.6vw;
  border-radius: 50px;
  @media (min-width: 100px) and (max-width: 992px) {
    width: 100px;
    height: 100px;
  }
}
.modal-width {
  @media (min-width: 1920px){
    width: 35vw !important;
  }
  @media (min-width: 768px) and(max-width: 1920px) {
    width: 45vw !important;
  }
  @media (min-width: 501px) and(max-width: 767px) {
    width: 60vw !important;
  }
  @media (max-width: 500px) {
    width: 80vw !important;
  }
}
.buyer-agent {
  margin-top: 1vw;
  @media (min-width: 501px) and(max-width: 767px) {
    margin-top: 2vw;
  }
  @media (max-width: 500px) {
    margin-top: 5vw;
  }
}

.messege-for-mobile-view {
  font-family: $primaryFontFamily;
  font-style: normal;
  padding: 50px !important;
}
.messege-box-header-margin {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.buyer-agent-modal.send-message-modal {
  padding: 50px !important;
  border-radius: 10px !important;
  margin-top: 2% !important;
}
.send-button-mobile-view {
  // background-color: #fc572b !important ;
  // border: 2px solid #fc572b !important ;
  margin-top: 10px !important;
  width: 120px !important ;
}
@media (min-width: 210px) and(max-width: 576px) {
  .buyer-agent-modal.send-message-modal {
    padding: 0px !important;
    border-radius: 10px !important;
    margin-top: 2% !important;
  }
  .messege-box-header-margin {
    margin-left: -3px !important;
    margin-right: -3px !important;
  }
  .messege-for-mobile-view {
    font-family: $primaryFontFamily;
    font-style: normal;
    padding: 30px !important;
    padding-bottom: 50px !important;
  }
  .send-button-mobile-view {
    width: 270px !important ;
  }
}

.schedule-date {
  margin-left: 45px;
  @media (max-width: 992px) {
    margin-left: 0px !important;
  }
}
.schedule-description {
  margin-left: 45px;
  @media (max-width: 992px) {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
}
.ant-drawer-body {
  @media (max-width: 767px) {
    padding: 0px !important;
  }
}

.value-loanmodal {
  font-size: 12px;
}
.value-loanmodal1 {
  font-size: 12px;
  @media (max-width: 500px) {
    margin-top: -45px !important;
  }
}
.value-loanmodal-name {
  font-size: 14px;
  margin-bottom: 0px !important;
  @media (max-width: 767px) {
    font-size: 10px;
    text-align: center;
  }
}
.website-loanmodal {
  font-size: 10px;
}
.center-item-modal {
  @media (min-width: 501px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.center-item-modal1 {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 501px) {
    margin-bottom: 1vw !important;
  }
}
.close-icon-fav {
  @media (max-width: 767px) {
    color: white;
  }
}
.market-place-dropDown {
  width: 100% !important;
  height: 100% !important;
  .ant-select:not(.ant-select-customize-input),
  .ant-select-selector {
    border: 1px solid #fff !important;
    color: black !important;
    background: white !important;
  }
}

.vedioLink {
  width: 50px !important;
  height: 35px !important;
  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 40px !important;
    height: 30px !important;
  }
  @media (min-width: 501px) and (max-width: 1599px) {
    width: 35px !important;
    height: 25px !important;
  }
  @media (max-width: 500px) {
    width: 25px !important;
    height: 20px !important;
  }
}

.ant-picker-ranges {
  display: none;
}
.details-area {
  margin-left: 20px;
  @media (max-width: 500px) {
    margin-left: 0px !important;
    margin-top: 10px;
  }
}
.details-area-home {
  margin-left: 20px;
  @media (max-width: 500px) {
    margin-left: 0px !important;
  }
}
.desktop-display-detail {
  @media (max-width: 500px) {
    display: none !important;
  }
}
.mobile-display {
  @media (min-width: 500px) {
    display: none !important;
  }
}

._39yvVzrxrpb0jfSJ06VKQG {
  display: none;
}
._2QxqScDSDuK4P0ny7KZKNr {
  display: none;
}
.ant-popover-placement-bottom .ant-popover-arrow {
  @media (max-width: 500px) {
    left: 75% !important;
  }
}
._26dq5g2bVLIO2cIh28C30n {
  display: none !important;
}
.testmonial-share {
  @media (max-width: 992px) {
    float: right;
  }
}
.Delete-testmonial {
  cursor: pointer;
  color: white;
  padding: 5px;
  border: 1px solid #ff0000;
  margin-left: 5px;
  @media (min-width: 993px) and(max-width: 1024px) {
    margin-left: 2px;
  }
}

.Next-Search-font {
  margin-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
  @media (max-width: 500px) {
    margin-top: 4px;
  }
}