
@import 'common.scss';

.OpenHeader {
    .ant-layout-header{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        // border-radius: 10px;
        opacity: 1;
        @media (min-width: 768px) and (max-width: 998px) { 
            height: 8.3vw;
            padding: 8px;
        }
        @media (min-width: 998px) and (max-width: 1200px) { 
            height: 7.3vw;
            padding: 10px;
        }
        @media (min-width: 1200px) and (max-width: 1400px) { 
        height: 6.3vw;
        padding: 8px;
        }
        @media (min-width: 1400px) {
            background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            // border-radius: 10px;
            height: 6.3vw;
            padding: 22px 22px 22px 22px !important;
            opacity: 1;
        }
    }
  
    .header-titles{
        padding-inline-end: 3.2vw;
        display: flex;
        text-align: center;
        justify-content: space-evenly;
    }
    .font-header{
         font-size: 0.90vw;
        // font-size: 16px;
        padding: 0.73vw;
        font-family: $primaryFontFamily;
        font-weight: 500;
    }
    .button{
        .ant-btn {
            line-height: 0.5715;
        }
    }

}


   

